import React, { useState } from 'react'
import { connect } from 'react-redux'
import config from '../../config'
import styles from './MapButton.module.scss'
import { selectMapAction, changeRouteNameAction, deleteRouteAction, clearRouteAction } from '../../actions'
import { Button } from '../../elements' 

const MapButtonComponent = ({ name, id, selectMap, active, changeRouteName, isNew, deleteRoute, clearRoute }) => {
  const [editName, setEditName] = useState(isNew)

  const editDone = (e) => {
    if (e.code === 'Enter') {
      if (e.target.value) {
        changeRouteName({ id, name: e.target.value })
      }
      setEditName(false)
    }
  }

  return (
    <div className={styles.wrapper} data-active={active}>
      <div
        className={styles.name}
        onClick={() => { selectMap(id) }}
        onDoubleClick={() => { setEditName(true) }}
      >
        {editName ? (
          <input className={styles.nameinput} onKeyPress={editDone} />
        ) : name}
      </div>
      <div className={styles.actions}>
        {!editName && <Button size='small' icon='IconBin' onClick={() => { clearRoute({id})}} />}
        {id != config.general.defaultRouteId && <Button size='small' icon='IconCancelCircle' onClick={() => {
          selectMap(config.general.defaultRouteId)
          deleteRoute({id})
        }} style={{ marginLeft: '4px'}} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({
  selectMap: (id) => { dispatch(selectMapAction(id)) },
  changeRouteName: (payload) => { dispatch(changeRouteNameAction(payload)) },
  deleteRoute: (payload) => { dispatch(deleteRouteAction(payload)) },
  clearRoute: (payload) => { dispatch(clearRouteAction(payload)) },
})

export const MapButton = connect(mapStateToProps, mapDispatchToProps)(MapButtonComponent)

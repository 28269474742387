import React from 'react'
import styled from 'styled-components'

const SVG = styled.svg`
  fill: ${(props) => props.color || '#000'};

  * { fill: ${(p) => p.color}; }
`

const Icon = ({
  size,
  children,
  ...props
}) => (
  <SVG
    width={size}
    height={size}
    viewBox='0 0 32 32'
    {...props}
  >
    {children}
  </SVG>
)

Icon.defaultProps = {
  size: '24px',
}

const createIcon = (path) => (p) => (
  <Icon {...p}>{path}</Icon>
)

export default createIcon

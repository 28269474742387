import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Container from './App'
import { Provider } from 'react-redux'
import { initializeStore } from './store'

const App = () => {
  return (
    <Provider store={initializeStore()}>
      <Container />
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

import React from 'react'
import { connect } from 'react-redux'
import styles from './SideBarRight.module.scss'
import { MarkerButton } from '..'

export const SideBarRightComponent = ({ state }) => {
  const renderMarkers = () => {
    const markers = state.locations.find((map) => map.id === state.controls.currentMap)?.locs
    
    return markers?.map((marker, index) => (
      <MarkerButton
        key={index}
        {...marker}
        index={index} first={index === 0}
        last={index === markers.length - 1}
        route={state.controls.currentMap}
      />
    ))
  }
  return (
    <div className={styles.wrapper}>
      {renderMarkers()}
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({})

export const SideBarRight = connect(mapStateToProps, mapDispatchToProps)(SideBarRightComponent)

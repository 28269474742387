import React, { useState } from 'react'
import { connect } from 'react-redux'
import styles from './Header.module.scss'
import { Button } from '../../elements'
import { generateGpx } from '../../utils'
import { toggleDirectAction, togglePoiAction, newRouteAction, setGeneratedGpxAction } from '../../actions'

export const HeaderComponent = ({
  state,
  toggleDirect,
  togglePoi,
  newRoute,
  setGeneratedGpx,
}) => {
  const [speedValue, setSpeedValue] = useState(state.controls.speed) 

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.logo}>
          GPX EDITOR
        </div>
        <div className={styles.navbar}>
        </div>
      </div>
      <div className={styles.subheader}>
        <div className={styles.subleft}>
          <Button
            onClick={() => { newRoute() }}
            icon='IconPlus'
            label="New Route"
            style={{ marginLeft: '8px' }}
          />
        </div>
        <div className={styles.subright}>
          {
            state.controls.currentMap == 'default' ? (
              <>
                <Button
                  onClick={() => { togglePoi('gym') }}
                  icon='IconGym'
                  style={{ marginLeft: '8px' }}
                  data-active={state.controls.poi === 'gym'}
                />
                <Button
                  onClick={() => { togglePoi('pokestop') }}
                  icon='IconPokeball'
                  style={{ marginLeft: '8px' }}
                  data-active={state.controls.poi === 'pokestop'}
                />
                <div className={styles.separator} />
              </>
            ) : (
              <>
                <div className={styles.speed}>
                  <input
                    value={speedValue}
                    style={{ width: '40px', textAlign: 'center' }}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setSpeedValue(0)
                      }
                      
                      if (Number.isInteger(parseInt(e.target.value))) {
                        setSpeedValue(parseInt(e.target.value))
                      }
                    }}
                  />
                  km/h
                </div>
                <div className={styles.separator} />
                <Button
                  onClick={() => {
                    const gpxString = generateGpx(state.locations.find((route) => route.id === state.controls.currentMap).locs, speedValue)
                    setGeneratedGpx(gpxString)
                  }}
                  icon='IconEmbed'
                  label='Copy GPX'
                  style={{ marginLeft: '8px' }}
                />
              </>
            )
          }
          <Button
            onClick={() => { toggleDirect() }}
            icon='IconMagicWand'
            style={{ marginLeft: '8px' }}
            data-red={state.controls.direct}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({
  toggleDirect: () => dispatch(toggleDirectAction()),
  newRoute: () => dispatch(newRouteAction()),
  togglePoi: (poi) => dispatch(togglePoiAction(poi)),
  setGeneratedGpx: (gpx) => dispatch(setGeneratedGpxAction(gpx)),
})

export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)

import { actionTypes } from './action-types'

export const toggleDirectAction = () => (dispatch) => {
  return dispatch({ type: actionTypes.TOGGLE_DIRECT })
}

export const selectMapAction = (mapId) => (dispatch) => {
  return dispatch({ type: actionTypes.SELECT_MAP, id: mapId })
}

export const togglePoiAction = (poi) => (dispatch) => {
  return dispatch({ type: actionTypes.TOGGLE_POI, poi })
}

export const setCurrentLocationAction = (pos) => (dispatch) => {
  return dispatch({ type: actionTypes.SET_CURRENT_LOCATION, pos })
}

export const setDirectPosAction = (pos) => (dispatch) => {
  return dispatch({ type: actionTypes.SET_DIRECT_POS, pos })
}

export const setGeneratedGpxAction = (gpx) => (dispatch) => {
  return dispatch({ type: actionTypes.SET_GENERATED_GPX, gpx })
}

export const highlightMarkerAction = (id) => (dispatch) => {
  return dispatch({ type: actionTypes.HIGHLIGHT_MARKER, id })
}

export const setSpeedAction = (speed) => (dispatch) => {
  return dispatch({ type: actionTypes.SET_SPEED, speed })
}

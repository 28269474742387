import React from 'react'
import { connect } from 'react-redux'
import styles from './MarkerButton.module.scss'
import { Button } from '../../elements'
import { moveMarkerAction, highlightMarkerAction } from '../../actions'
import classNames from 'classnames'

const MarkerButtonComponent = ({ index, lng, lat, first, last, state, id, route, moveMarker, highlightMarker }) => {
  const handleMove = (to) => {
    setTimeout(() => {
      moveMarker({route, id, to })
    }, (id === state.controls.highlightedMarker) ? 0 : 200)
  }

  return (
    <div className={classNames(styles.wrapper, (id === state.controls.highlightedMarker) ? styles.outline : null )} onClick={() => { highlightMarker(id) }}>
      <div className={styles.index}>{index}</div>
      <div className={styles.position}>
        <div>{id.substr(0,13)}</div>
      </div>
      <div className={styles.actions}>
        <div className={styles.action}>
          {!first && <Button size='small' icon='IconArrowUp2' onClick={() => { handleMove('up') }} />}
        </div>
        <div className={styles.action}>
          {!last && <Button size='small' icon='IconArrowDown2' onClick={() => { handleMove('down') }} />}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({
  moveMarker: ({ route, id, to }) => dispatch(moveMarkerAction({ route, id, to })),
  highlightMarker: (id) => dispatch(highlightMarkerAction(id))
})

export const MarkerButton = connect(mapStateToProps, mapDispatchToProps)(MarkerButtonComponent)

import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import { HomePage, ErrorPage } from './routes'
import { Header, SideBarLeft, SideBarRight } from './components'
import styles from './App.module.scss'
import { setGeneratedGpxAction } from './actions'

const App = ({ state, setGeneratedGpx }) => {
  return (
    <Router>
      {state.controls.generatedGpx && (
        <div className={styles.modal} onClick={() => { setGeneratedGpx(null) }}>
          <div className={styles.modal_inner} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
            <textarea value={state.controls.generatedGpx}></textarea>
          </div>
        </div>
      )}
      <Header />
      <div className={styles.wrapper}>
        <SideBarLeft />
        <div style={{ flexGrow: 1 }}>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/gpx' component={HomePage} />
            <Route component={ErrorPage} />
          </Switch> 
        </div>
        <SideBarRight />
      </div>
    </Router>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({
  setGeneratedGpx: (gpx) => dispatch(setGeneratedGpxAction(gpx)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)

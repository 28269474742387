const actionTypes = {
  TOGGLE_DIRECT: 'TOGGLE_DIRECT',
  SELECT_MAP: 'SELECT_MAP',
  TOGGLE_POI: 'TOGGLE_POI',
  SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',
  SET_DIRECT_POS: 'SET_DIRECT_POS',
  SET_GENERATED_GPX: 'SET_GENERATED_GPX',
  HIGHLIGHT_MARKER: 'HIGHLIGHT_MARKER',
  SET_SPEED: 'SET_SPEED',
}

export default actionTypes

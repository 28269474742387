import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import styles from './Marker.module.scss'
import { IconGym, IconPokeball, IconMagicWand } from '../../elements/Icon'

const markerIcons = {
  gym: IconGym,
  pokestop: IconPokeball,
  direct: IconMagicWand,
}

const MarkerComponent = ({ text, onClick, type, hideContent, id, state }) => {
  const [hightlighted, setHighlighted] = useState(id === state.controls.highlightedMarker)

  useEffect(() => {
    setHighlighted(id === state.controls.highlightedMarker)
  }, [state])

  let IconComponent = null

  if (type) {
    IconComponent = markerIcons[type]
  }

  console.log(id === state.controls.highlightedMarker)

  return (
    <div
      data-type={type ? type : null}
      className={classNames(styles.wrapper, (hightlighted) ? styles.highlight : null )}
      onClick={onClick}
    >
      <div className={styles.text}>
        {!hideContent ? (IconComponent ? <IconComponent color='#000' size='20px' /> : text) : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({
})

export const Marker = connect(mapStateToProps, mapDispatchToProps)(MarkerComponent)

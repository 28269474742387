import React, { useRef, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import styles from './SearchBox.module.scss'
import { Button } from '../../elements'
import { setCurrentLocationAction } from '../../actions'

const SearchBoxComponent = ({ maps, onPlacesChanged, placeholder, setCurrentLocation, ...props }) => {
  const input = useRef(null)
  const searchBox = useRef(null)

  const handleOnPlacesChanged = useCallback(() => {
    if (onPlacesChanged) {
      onPlacesChanged(searchBox.current.getPlaces())
    }
  }, [onPlacesChanged, searchBox])

  useEffect(() => {
    if (!searchBox.current && maps) {
      searchBox.current = new maps.places.SearchBox(input.current)
      searchBox.current.addListener('places_changed', handleOnPlacesChanged)
    }

    return () => {
      if (maps) {
        searchBox.current = null
        maps.event.clearInstanceListeners(searchBox)
      }
    }
  }, [maps, handleOnPlacesChanged])

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => { 
          navigator.geolocation.getCurrentPosition(function(position) {
            setCurrentLocation(position.coords)
          });
        }}
        icon='IconCurrentLocation'
        style={{ marginLeft: '8px' }}
      />
      <input {...props} ref={input} placeholder={placeholder} type='text' />
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({
  setCurrentLocation: (pos) => dispatch(setCurrentLocationAction(pos)),
})

export const SearchBox = connect(mapStateToProps, mapDispatchToProps)(SearchBoxComponent)

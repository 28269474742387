import { connect } from 'react-redux'

const Error = ({ state, handleTick }) => {
  return (
    <div style={{ margin: '10px' }}>
      404
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({
  handleTick: () => dispatch({ type: 'TICK' }),
})

export const ErrorPage = connect(mapStateToProps, mapDispatchToProps)(Error)

import { actionTypes } from './action-types'

export const addLocationAction = (payload) => (dispatch) => {
  return dispatch({ type: actionTypes.ADD_LOCATION, payload })
}

export const removeLocationAction = (payload) => (dispatch) => {
  return dispatch({ type: actionTypes.REMOVE_LOCATION, payload })
}

export const saveLocationsAction = () => (dispatch) => {
  return dispatch({ type: actionTypes.SAVE_LOCATIONS })
}

export const loadLocationsAction = () => (dispatch) => {
  return dispatch({ type: actionTypes.LOAD_LOCATIONS })
}

export const changeRouteNameAction = (payload) => (dispatch) => {
  return dispatch({ type: actionTypes.CHANGE_ROUTE_NAME, payload })
}

export const newRouteAction = () => (dispatch) => {
  return dispatch({ type: actionTypes.NEW_ROUTE })
}

export const deleteRouteAction = (payload) => (dispatch) => {
  return dispatch({ type: actionTypes.DELETE_ROUTE, payload })
}

export const clearRouteAction = (payload) => (dispatch) => {
  return dispatch({ type: actionTypes.CLEAR_ROUTE, payload })
}

export const moveMarkerAction = (payload) => (dispatch) => {
  return dispatch({ type: actionTypes.MOVE_MARKER, payload })
}

const actionTypes = {
  ADD_LOCATION: 'ADD_LOCATION',
  REMOVE_LOCATION: 'REMOVE_LOCATION',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  SAVE_LOCATIONS: 'SAVE_LOCATIONS',
  LOAD_LOCATIONS: 'LOAD_LOCATIONS',
  CHANGE_ROUTE_NAME: 'CHANGE_ROUTE_NAME',
  NEW_ROUTE: 'NEW_ROUTE',
  DELETE_ROUTE: 'DELETE_ROUTE',
  CLEAR_ROUTE: 'CLEAR_ROUTE',
  MOVE_MARKER: 'MOVE_MARKER',
}

export default actionTypes

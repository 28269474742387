import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import GoogleMapReact from 'google-map-react'
import config from '../config'
import { generateGpx } from '../utils'
import { SearchBox, Marker } from '../components'
import {
  addLocationAction,
  removeLocationAction,
  loadLocationsAction,
  setDirectPosAction,
  setGeneratedGpxAction,
} from '../actions'

const Home = ({ state, addLocation, removeLocation, loadLocations, setDirectPos, setGeneratedGpx }) => {
  const [coords, setCoords] = useState({ lat: 38.42372136114217, lng: 27.142838805200412 })
  const [apiReady, setApiReady] = useState(false)
  const [map, setMap] = useState(null)
  const [googleMaps, setGoogleMaps] = useState(null)

  const isDefaultSelected = useMemo(
    () => (state.controls.currentMap === config.general.defaultRouteId),
    [state.controls.currentMap, config.general.defaultRouteId],
  );

  useEffect(() => {
    loadLocations()
  }, [])

  useEffect(() => {
    setCoords(state.controls.pos)
  }, [state.controls.pos])

  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      setMap(map)
      setGoogleMaps(maps)
      setApiReady(true)
    }
  }

  const getRouteData = (isPois) => (
    state.locations.find((route) => (
      route.id === (isPois ? config.general.defaultRouteId : state.controls.currentMap)
    ))
  )

  const handleClick = ({ location, isPois, isMarker, shift }) => {
    if (state.controls.direct || shift) {
      setDirectPos(location)
      const gpxString = generateGpx([location])
      setGeneratedGpx(gpxString)
    } else {
      if (!isMarker || (isPois && !isDefaultSelected)) {
        addLocation({
          location,
          id: state.controls.currentMap,
          type: (!isMarker && state.controls.poi)
        })
      } else {
        removeLocation({
          markerId: location.id,
          mapId: state.controls.currentMap
        })
      }
    }
  }

  const renderCurrentMarkers = (isPois) => {
    return getRouteData(isPois).locs.map((loc, index) => (
      <Marker
        id={loc.id}
        type={loc.type}
        key={`${isPois ? '_' : ''} ${loc.id}`}
        hideContent={isPois && (!isDefaultSelected)}
        lat={loc.lat}
        lng={loc.lng}
        text={index}
        onClick={(e) => {
          handleClick({ isPois, location: loc, isMarker: true, shift: e.shiftKey }) 
        }}
      />
    ))
  }

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        {apiReady && (
          <SearchBox
            placeholder='123 anywhere st.'
            map={map}
            maps={googleMaps}
            onPlacesChanged={(e) => {
              if (e?.length) {
                setCoords({
                  lat: e[0].geometry.location.lat(),
                  lng: e[0].geometry.location.lng(),
                })
              }
            }}
          />
        )}
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyA_idMS_64_OGwm5vyRHKM5yIUKpDKwAiM' }}
          defaultCenter={coords}
          defaultZoom={17}
          center={coords}
          onClick={(e) => { 
            handleClick({ location: e, shift: e.event.shiftKey })
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {renderCurrentMarkers(true)}
          {!isDefaultSelected && renderCurrentMarkers()}

          {state.controls.directPos && (
            <Marker
              type='direct'
              lat={state.controls.directPos.lat}
              lng={state.controls.directPos.lng}
              onClick={() => { setDirectPos(null) }}
            />
          )}
        </GoogleMapReact>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({
  removeLocation: (data) => dispatch(removeLocationAction(data)),
  addLocation: (data) => dispatch(addLocationAction(data)),
  setDirectPos: (data) => dispatch(setDirectPosAction(data)),
  setGeneratedGpx: (data) => dispatch(setGeneratedGpxAction(data)),
  loadLocations: () => dispatch(loadLocationsAction()),
})

export const HomePage = connect(mapStateToProps, mapDispatchToProps)(Home)

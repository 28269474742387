import { actionTypes } from '../actions/action-types'

const initialState = {
  direct: false,
  currentMap: 'default',
  poi: 'pokestop',
  pos: { lat: 38.42372136114217, lng: 27.142838805200412 },
  directPos: null,
  generatedGpx: '',
  highlightedMarker: null,
  speed: 20,
}

export default function controlsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_DIRECT:
      return {
        ...state,
        direct: !state.direct
      }
    case actionTypes.SET_GENERATED_GPX:
      return {
        ...state,
        generatedGpx: action.gpx
      }
    case actionTypes.SET_DIRECT_POS:
      return {
        ...state,
        directPos: action.pos
      }
    case actionTypes.TOGGLE_POI:
      return {
        ...state,
        poi: action.poi ? action.poi : (state.poi === 'gym' ? 'pokestop' : 'gym')
      }
    case actionTypes.SELECT_MAP:
      return {
        ...state,
        currentMap: action.id
      }
    case actionTypes.SET_CURRENT_LOCATION:
      return {
        ...state,
        pos: { lat: action.pos.latitude, lng: action.pos.longitude, time: Date.now() }
      }
    case actionTypes.HIGHLIGHT_MARKER:
      return {
        ...state,
        highlightedMarker: action.id,
      }
    case actionTypes.SET_SPEED:
      return {
        ...state,
        speed: action.speed,
      }
    default:
      return state
  }
}

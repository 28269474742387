import copy from 'copy-to-clipboard'

let wrapper = `<?xml version="1.0"?>
<gpx version="1.1" creator="gpxgenerator.com">
	{{wpts}}
</gpx>`

let currentTime = Date.now()

let timeConvert = (tm) => (`${new Date(tm).toISOString().split('.')[0]}Z`)

export const generateGpx = (array, speedKm = 20) => {


  let prev = null
  let wptString = array.map((wpt, index) => {
    let currentWpt = wpt

    let latDiff = 0
    let lngDiff = 0
    let totalDiff = 0

    if (prev) {
      latDiff = Math.abs(prev.lat - wpt.lat)
      lngDiff = Math.abs(prev.lng - wpt.lng)
      totalDiff = Math.sqrt(Math.pow(latDiff, 2) + Math.pow(lngDiff, 2)) * 90000
    }

    let timeDiffInSeconds =  (totalDiff / speedKm) * 3.6

    currentTime = currentTime + timeDiffInSeconds * 1000
    let currentDate = timeConvert(currentTime)

    prev = wpt

    return `<wpt lat="${currentWpt.lat}" lon="${currentWpt.lng}">
      <diff>${parseInt(totalDiff)}</diff>
          <time>${currentDate}</time>
      </wpt>
    `
  })

  let gpxString = wrapper.replace('{{wpts}}', wptString.join(''))

  copy(gpxString)
  return gpxString
}

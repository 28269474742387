import React from 'react'
import { connect } from 'react-redux'
import styles from './SideBarLeft.module.scss'
import { MapButton } from '../'

export const SideBarLeftComponent = ({ state }) => {
  const renderMaps = () => (
    state.locations.map((map) => {
      return (
        <MapButton
          key={map.id}
          {...map}
          active={state.controls.currentMap === map.id}
        />
      )
    })
  )

  return (
    <div className={styles.wrapper}>
      {renderMaps()}
    </div>
  )
}

const mapStateToProps = (state) => ({ state })

const mapDispatchToProps = (dispatch) => ({})

export const SideBarLeft = connect(mapStateToProps, mapDispatchToProps)(SideBarLeftComponent)

import React from 'react'
import classnames from 'classnames'
import styles from './Button.module.scss'
import { Icon } from '../'

export const Button = ({ children, className, icon, label, size, ...props }) => {
  const IconComponent = icon ? Icon[icon] : null

  return (
    <div className={classnames(className, styles.wrapper, styles[`size-${size}`])} {...props}>
      {icon ? (
        <IconComponent
          size={size === 'small' ? '16px' : '24px'}
          style={(children || label) ? { marginRight: '8px' } : {}}
          color='#FFFFFF'
        />
      ) : null }
      {children || label}
    </div>
  )
}
import { v4 as uuid } from 'uuid'
import { actionTypes } from '../actions/action-types'

const initialState = [
  {
    id: 'default',
    name: 'POIS',
    locs: []
  },
  {
    id: 'route-1',
    name: 'Route 1',
    locs: []
  }
]

export default function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_LOCATION:
      let addState = state
      let addMapIndex = addState.findIndex((map) => map.id === action.payload.id)
      let addMarker = {
        lng: action.payload.location.lng,
        lat: action.payload.location.lat,
        id: uuid(),
      }

      if (addMapIndex === 0) {
        addMarker.type = action.payload.type
      }

      addState[addMapIndex].locs.push(addMarker)

      localStorage.setItem('locations', JSON.stringify(addState))
      return [
        ...addState,
      ]
    case actionTypes.REMOVE_LOCATION:
      let removeState = state
      let removeMapIndex = removeState.findIndex((map) => map.id === action.payload.mapId)
      removeState[removeMapIndex].locs = removeState[removeMapIndex].locs.filter(({ id }) => (id !== action.payload.markerId))

      localStorage.setItem('locations', JSON.stringify(removeState))
      return [
        ...removeState
      ]
    case actionTypes.SAVE_LOCATIONS:
      localStorage.setItem('locations', JSON.stringify(state))
      return state
    case actionTypes.LOAD_LOCATIONS:
      return JSON.parse(localStorage.getItem('locations')) || initialState
    case actionTypes.CHANGE_ROUTE_NAME:
      let changeNameState = [...state]
      let targetIndex = changeNameState.findIndex((route) => route.id === action.payload.id)
      changeNameState[targetIndex].name = action.payload.name
      delete changeNameState[targetIndex].isNew
      localStorage.setItem('locations', JSON.stringify(changeNameState))
      return changeNameState
    case actionTypes.NEW_ROUTE:
      let newRouteState = [
        ...state,
        {
          id: uuid(),
          name: 'New Route',
          locs: [],
          isNew: true,
        }
      ]
      localStorage.setItem('locations', JSON.stringify(newRouteState))
      return newRouteState
    case actionTypes.DELETE_ROUTE:
      let deleteRouteState = [...state]
      let deleteIndex = deleteRouteState.findIndex((r) => r.id === action.payload.id )
      deleteRouteState.splice(deleteIndex, 1)
      localStorage.setItem('locations', JSON.stringify(deleteRouteState))
      return deleteRouteState
    case actionTypes.CLEAR_ROUTE:
      let clearState = [...state]
      let clearIndex = clearState.findIndex((r) => r.id === action.payload.id )
      clearState[clearIndex].locs = []
      localStorage.setItem('locations', JSON.stringify(clearState))
      return clearState
    case actionTypes.MOVE_MARKER:
      let moveMarkerState = [...state]
      let routeIndex = moveMarkerState.findIndex((route) => route.id === action.payload.route )
      let locIndex = moveMarkerState[routeIndex].locs.findIndex((loc) => loc.id === action.payload.id)
      let swap = {}

      if (action.payload.to === 'up') {
        swap = moveMarkerState[routeIndex].locs[locIndex - 1]
        moveMarkerState[routeIndex].locs[locIndex - 1] = moveMarkerState[routeIndex].locs[locIndex]
        moveMarkerState[routeIndex].locs[locIndex] = swap
      } else {
        swap = moveMarkerState[routeIndex].locs[locIndex + 1]
        moveMarkerState[routeIndex].locs[locIndex + 1] = moveMarkerState[routeIndex].locs[locIndex]
        moveMarkerState[routeIndex].locs[locIndex] = swap
      }

      swap = {}
      return moveMarkerState 
    default:
      return state
  }
}
